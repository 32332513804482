import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { Device } from "Rwd/Scripts/Device";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";

export class SectionPageView extends UiComponent {
    private readonly BENEFIT_WRAPPER: string = ".benefits-wrapper";
    private readonly WRAPPER_ACTIVE_CLASS: string = "benefit-active";
    private readonly WRAPPER_DISABLE_CLASS: string = "benefit-disable";
    private readonly BENEFIT_CONTENT: string = ".benefit-content";
    private readonly BENEFIT_ANNOTATION: string = ".benefit-annotation";
    private readonly READ_MORE_LINK: string = ".js-read-more";
    private readonly CLOSE_LINK: string = ".js-close";
    private readonly SECTION_TITLE: string = ".section-title";
    private readonly VIDEO_CAROUSEL_ITEM: string = ".ui-video-carousel-item";
    private readonly WRAPPER_SLIDE: string = ".slide";
    private readonly ANIMATION_LENGTH: number = 200;
    private readonly SIZE_2_INDEX: number = 1;
    private readonly RESIZE_TIMEOUT: number = 200;
    private _maxHeight: number = 0;
    private _device: Device;
    private _resizeTimer: number;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory,
        @Inject device: Device
    ) {
        super(componentFactory, binder, loggerFactory);
        this._device = device;
    }

    /**
     * Initialization
     */
    public init(): void {
        this._bindEvents();
    }

    // Close content of benefit area
    private _closeBenefitArea(event: JQuery.TriggeredEvent): void {
        const clickedElement = event.target;

        const clickedContent = Utils.closest(clickedElement, this.BENEFIT_CONTENT);
        if (!clickedContent) {
            return;
        }

        const position = $(clickedContent).offset()?.top ?? 0;
        clickedContent.classList.remove(this.WRAPPER_ACTIVE_CLASS);

        const wrapperElement = Utils.closest(clickedElement, this.BENEFIT_WRAPPER);
        if (!wrapperElement) {
            return;
        }

        const benefits = Utils.find(wrapperElement, this.BENEFIT_CONTENT);
        benefits.forEach((benefit) => Utils.removeClass(benefit, this.WRAPPER_DISABLE_CLASS));

        $("html, body").animate(
            { scrollTop: position - ($(this.SECTION_TITLE).outerHeight(true) ?? 0) },
            this.ANIMATION_LENGTH
        );
    }

    // Bind events
    private _bindEvents(): void {
        this._binder.bindDelegatedClick(this.READ_MORE_LINK, this._showBenefitArea.bind(this));
        this._binder.bindDelegatedClick(this.CLOSE_LINK, this._closeBenefitArea.bind(this));
        this._device.bindReady(() => {
            this._setProperties();
        });
        this._device.bindResize(() => {
            if (this._resizeTimer) {
                window.clearTimeout(this._resizeTimer);
            }
            this._resizeTimer = window.setTimeout(() => {
                this._reinitProperties();
            }, this.RESIZE_TIMEOUT);
        });
    }

    // Set properties
    private _setProperties(): void {
        const benefits = Utils.find(this.BENEFIT_WRAPPER, this.BENEFIT_ANNOTATION);
        const slides = Utils.find(this.VIDEO_CAROUSEL_ITEM, this.WRAPPER_SLIDE);

        this._setHeight(slides);
        this._setHeight(benefits);
    }

    // Reinitialization properties
    private _reinitProperties(): void {
        const benefits = Utils.find(this.BENEFIT_WRAPPER, this.BENEFIT_ANNOTATION);
        const slides = Utils.find(this.VIDEO_CAROUSEL_ITEM, this.WRAPPER_SLIDE);

        this._resetHeight(slides);
        this._setHeight(slides);
        this._resetHeight(benefits);
        if (this._device.activeBreakpoint.index > this.SIZE_2_INDEX) {
            this._setHeight(benefits);
        }
    }

    // Show benefit area
    private _showBenefitArea(event: JQuery.TriggeredEvent): void {
        const clickedElement = event.target;

        const contentElement = Utils.closest(clickedElement, this.BENEFIT_CONTENT);
        if (!contentElement) {
            return;
        }
        contentElement.classList.add(this.WRAPPER_ACTIVE_CLASS);

        const wrapper = Utils.closest(clickedElement, this.BENEFIT_WRAPPER);
        if (!wrapper) {
            return;
        }

        const benefits = Utils.find(
            wrapper,
            `${this.BENEFIT_CONTENT}:not(.${this.WRAPPER_ACTIVE_CLASS})`
        );
        benefits.forEach((benefit) => Utils.addClass(benefit, this.WRAPPER_DISABLE_CLASS));
    }

    // Get and set max height of contents
    private _setHeight(contents: Element[]): void {
        this._maxHeight = 0;

        contents.forEach((content) => {
            const height = Utils.getHeight(content);
            const maxHeight = Math.max(this._maxHeight, height);

            this._maxHeight = maxHeight;
        });

        contents.forEach((content) => Utils.setCss(content, "min-height", this._maxHeight));
    }

    // Reset height of contents
    private _resetHeight(contents: Element[]): void {
        contents.forEach((content) => Utils.setCss(content, "min-height", "auto"));
        this._maxHeight = 0;
    }
}
