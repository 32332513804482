import { Inject } from "typescript-ioc";
import { ILogger } from "Logging/Scripts/ILogger";
import { SectionPageView } from "./SectionPage";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";

export class BenefitAreaFactory {
    public get key(): string {
        return "BenefitAreaFactory";
    }

    public readonly BENEFIT_SELECTOR: string = ".js-benefits";
    private _uiComponentFactory: UiComponentFactory;
    protected readonly _logger: ILogger;

    constructor(
        @Inject uiComponentFactory: UiComponentFactory,
        @Inject loggerFactory: LoggerFactory
    ) {
        this._logger = loggerFactory.getLogger(this.key);
        this._uiComponentFactory = uiComponentFactory;
    }

    /**
     * Creates new instance based on selector.
     * @param selector Selector string which will be the context.
     * @returns New instance.
     */
    public create(selector: Element | string): SectionPageView | null {
        if (!selector) {
            this._logger.error("No selector provided");

            return null;
        }

        return this._uiComponentFactory.createBase(SectionPageView, selector);
    }

    /**
     * Creates instances automatically if there are some elements which match BENEFIT selector in context
     * @param context Element or selector string for context in which method will search for benefit.
     * If no context passed, than document body will be the context.
     */
    public createAll(context: Element | string = "body"): void {
        const benefits = Utils.find(context, this.BENEFIT_SELECTOR);
        benefits.forEach((benefit) => this.create(benefit));
    }
}
