import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { BenefitAreaFactory } from "./BenefitAreaFactory";
import { SectionPagesSimplified } from "./SectionPagesSimplified";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";

import "../Styles/section-pages.css";

const benefitAreaFactory = Container.get(BenefitAreaFactory);

// auto init on page
Container.get(ReadyHelper).bindDomReady(() => benefitAreaFactory.createAll());
Container.get(UiComponentFactory).createBase(SectionPagesSimplified, "body");
