import { Inject } from "typescript-ioc";
import { UiComponent } from "Ui/Scripts/UiComponent";
import { UiComponentFactory } from "Ui/Scripts/UiComponentFactory";
import { EventBinder } from "Events/Scripts/EventBinder";
import { LoggerFactory } from "Logging/Scripts/LoggerFactory";
import { Utils } from "Helpers/Scripts/Utils";

export class SectionPagesSimplified extends UiComponent {
    private readonly HERO_LINK: string = ".section-pages-link-module a";
    private readonly MOBILE_STICKY_BAR_HEIGHT: number = 60;
    private readonly STICKY_BAR_HEIGHT: number = 80;
    private readonly ANIMATION_LENGTH: number = 300;
    private readonly MOBILE_INDEX: number = 3;

    constructor(
        @Inject componentFactory: UiComponentFactory,
        @Inject binder: EventBinder,
        @Inject loggerFactory: LoggerFactory
    ) {
        super(componentFactory, binder, loggerFactory);
    }

    /**
     * Initialization
     */
    public init(): void {
        this._bindEvents();
    }

    // Smooth scroll to anchor
    private _smoothScrollToAnchor(event: JQuery.TriggeredEvent): void {
        const { target } = event;
        if (typeof target !== "object") {
            return;
        }
        const clickedElement = target as HTMLElement;
        const link = Utils.getAttr(clickedElement, "href") ?? "";
        if (link[0] !== "#") {
            return;
        }
        event.preventDefault();

        const isMobile = ori.device && ori.device.activeBreakpoint.index <= this.MOBILE_INDEX;
        const position =
            ($(link).offset()?.top ?? 0) -
            (isMobile ? this.MOBILE_STICKY_BAR_HEIGHT : this.STICKY_BAR_HEIGHT);

        $("html, body").animate({ scrollTop: position }, this.ANIMATION_LENGTH);
    }

    // Bind events
    private _bindEvents(): void {
        this._binder.bindDelegatedClick(this.HERO_LINK, this._smoothScrollToAnchor.bind(this));
    }
}
